:root {
  --color-background: #F5F7FB;
  --color-primary: #ffcd00;
  --color-primary-hover: #FFF0B2;
  --color-primary-hover2: #FFE166;
  --color-secondary: #DDE8FB;
  --color-secondary-hover: #b3c7ea;
  --color-error: #FB5935;
  --color-warn: #ff9100;
  --color-read: #6775d2;
  --color-dark: #071A39;
  --color-light: #ffffff;
  --color-input-border: #5273a7;
  --color-reset-btn-disabled: #737373;
  --color-notification-item-grey: #f5f5f5;
  --color-notification-detail-btn: #395179;
  --color-chart-grey-btn: #E7EAEE;
  --color-chart-grey-btn-hover: #c8d4e1;
  --color-chart-grey-text: #64748B;
  --color-chart-pie-title: #828282;
  --color-chart-pie-subtitle: #4F4F4F;
  --color-box-shadow: #010b1b0d;
  --color-separator: #ECECEC;
}
