/* You can add global styles to this file, and also import other style files */
@import '../node_modules/quill/dist/quill.snow.css';
@import '../node_modules/quill/dist/quill.bubble.css';

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('/assets/fonts/IBM_Plex_Sans/IBMPlexSans-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans Regular';
  src: url('/assets/fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans SemiBold';
  src: url('/assets/fonts/IBM_Plex_Sans/IBMPlexSans-SemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans Condensed Bold';
  src: url('/assets/fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans Condensed";
  src: url('/assets/fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans Condensed Medium";
  src: url('/assets/fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-Medium.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans Bold";
  src: url('/assets/fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

html, body {
  padding: 0;
  margin: 0;
  height: 100%;
}

* {
  scrollbar-color: var(--color-primary) #F3F3F3;
  scrollbar-width: thin;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}